@charset "UTF-8";

//setting
$main-color: #333333;
$key-color1: #1c2d66;
$key-color2: #3fa9b7;
$back-color1: #f2f2ed;

$font-family-go: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3",  "メイリオ", Meiryo, sans-serif;
$font-family-min:'Shippori Mincho', "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", "YuMincho", "HGS明朝E", serif;

$font-family-eng: "Cinzel", 'Shippori Mincho', serif;

// media query
$tab:1024px; //960px; // タブレット
$sp: 600px;//480px; // スマホ

// max-width
$max-width: 1000px;

@mixin media-tab {
  @media only screen and (max-width: ($tab)) {
    @content;
  }
}
// 呼び出すときは　@include media-tab { }
@mixin media-sp {
  @media only screen and (max-width: ($sp)) {
    @content;
  }
}
// 呼び出すときは　@include media-sp { }

//clearfix 呼び出すときは　@include clearfix;
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
