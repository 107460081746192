@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */

@use "setting" as *;


/************************************

MAINIMAGE

************************************/

#top #mainimage_waku {
  margin-top: 75px;
  width: 100%;
  //height: 0;
  //padding-top: 46%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #333;
@media only screen and (max-width: 1060px) {
position: absolute;

}
@media only screen and (max-width: 520px) {
margin-top: 112px;
    height: auto;
    padding-top: 0;
}
}
/*
#brand_banner{
height:120px;
ul{
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content: center;
align-items: center;
}
li{
margin:0 10px;
height:70px;
overflow: hidden;
iframe{
overflow: hidden;
}
}
}*/
#top #mainimage {
  display: block;

  width: 100%;

  margin: 0 auto;
  overflow: hidden;
  //position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 2s;
  text-align: center;
  background-color: #333;
}
#top #mainimage img {
  width: 100%;
}

///rolex
#rolox_slide{
position: relative;
.text{
z-index: 2;
position: absolute;
top:0;
right:0;
width:50%;
height:100%;
//background-color: aqua;
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content:center;
align-items: center;
@include media-sp { 
top:100vw;
bottom:auto;
width:100%;
height:31.25vw;
}
}
.text2{
//height:70px;
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content:center;
align-items: center;
h3{
font-family:  Helvetica, Arial,"sans-serif";
font-size: 32px;
line-height: 1;
color:#000;
letter-spacing: 0;
margin-bottom: 30px;
text-align: center;
width:100%;
small{
font-size: 16px;
}
@include media-tab { 
font-size: 26px;
margin-bottom: 20px;
}
@include media-sp { 
margin-bottom: 16px;
text-align: center;
}
@media only screen and (max-width:400px){
font-size: 6vw;
margin-bottom: 3.2vw;
}
}
.rolex_bt{
background-color: #127749;
border:1px solid #127749;
border-radius: 2em;
font-size: 14px;
line-height: 1;
color:#fff;
padding:10px 0;
width:160px;
text-align: center;
letter-spacing: 0;
transition: 0.5s;
@include media-tab { 
font-size: 12px;
}
@include media-sp { 
margin:0 auto;
width:140px;
}
@media only screen and (max-width:400px){
font-size: 3.2vw;
padding:1.8vw 0;
}
&:hover{
background-color: #fff;
color:#127749;
}
}
}
}


#top #mainimage .slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

/* Arrows */
#top #mainimage .slick-prev,
#top #mainimage .slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;
  z-index: 100;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
#top #mainimage .slick-prev:hover,
#top #mainimage .slick-prev:focus,
#top #mainimage .slick-next:hover,
#top #mainimage .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
#top #mainimage .slick-prev:hover:before,
#top #mainimage .slick-prev:focus:before,
#top #mainimage .slick-next:hover:before,
#top #mainimage .slick-next:focus:before {
  opacity: 1;
}
#top #mainimage .slick-prev:before,
#top #mainimage .slick-next:before {
  font-family: "FontAwesome";
  font-size: 40px;
  line-height: 1;

  opacity: 0.75;
  color: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#top #mainimage .slick-prev {
  left: 25px;
  right: auto;
}

#top #mainimage .slick-next {
  right: 25px;
}

@include media-tab {
  #top #mainimage_waku {
    
  }
  #top #mainimage_waku .pc {
    display: block !important;
  }
  #top #mainimage {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 1;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  #top #mainimage_waku .sp {
    display: none !important;
  }
  #top #mainimage_waku .slick-prev {
    left: 10px;
    right: auto;
    z-index: 998;
  }

  #top #mainimage_waku .slick-next {
    right: 10px;
  }
}
@include media-sp {
  #top #mainimage_waku {
    
  }
  #top #mainimage_waku .pc {
    display: none !important;
  }
  #top #mainimage_waku .sp {
    display: block !important;
  }
  #top #mainimage_waku .slick-prev {
    left: 10px;
    right: auto;
  }

  #top #mainimage_waku .slick-next {
    right: 10px t;
  }
}

/************************************

top_sec2

************************************/
#top #main {
  position: absolute;
  margin-top: 52%;
  z-index: 5;
}

#top #main #top_sec2 {
  /*top:84vh;*/
  background: #fff;
  width: 100%;

  position: relative;
}
@media only screen and (max-width: 1130px) {
  #top #main {

    margin-top: 46%;

  }
}
@media only screen and (max-width: 1060px) {
  #top #main {
    position: absolute;
    margin-top: 45%;
    z-index: 5;
  }
}
@include media-sp {
  #top #main {
    margin-top: 131.25%;
  }
}
/************************************

Annyversary

************************************/
#top #anniversary {
  background: #0f0f14;
  color: #fff;
  padding: 50px 0;
}
#top #anniversary h2 {
  transition: 2s;
  font-size: 140%;
  letter-spacing: 0.1em;
}
#top #anniversary p {
  font-size: 80%;
  letter-spacing: 0.05em;
  margin: 0 0 0px;
  line-height: 140%;
}

/************************************

BRAND

************************************/
#top #brand {
  background: #0f0f14;
  color: #fff;
  padding: 50px 0;
}
#top #brand h2 {
  transition: 2s;
  margin: 0 0 10px;
}
#top #brand p {
  font-size: 100%;
  letter-spacing: 0.05em;
  margin: 0 0 20px;
  line-height: 140%;
}
#top #brand p small {
  padding-left: 8px;
  font-size: 70%;
}
#top #brand .recruit {
  margin: 0 0 20px;
}
#top #brand .recruit a {
  text-decoration: none;
  color: #fff;
  display: block;
  width: 150px;
  margin: 0 auto;
  padding: 15px 30px 15px 40px;
  background: $key-color2;
  font-size: 90%;
  position: relative;
  transition: 0.5s;
}

#top #brand .recruit a::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Safari用 */
  transform: translateY(-50%);
  left: 15px;
  color: #fff;
  font-size: 12px;
  font-family: "FontAwesome";
  content: "\f105";
}
#top #brand .recruit a:hover {
  opacity: 0.7;
}
#top #brand .brandslider {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background: #0f0f14;
  width: 90%;
  max-width: 1600px;
  margin: 0px auto 20px;
}
#top #brand li {
  margin: 0 5px;
}
#top #brand img {
  height: auto;
}

/* Flashing */
#top #brand img:hover {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

/* sns */
#top #brand .sns {
  margin: 40px auto 0;
  width: 90%;
		display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content: center;
}
#top #brand .sns li{
width: 18%;
  margin: 1%;
		box-sizing: border-box;
		@include media-tab { 
		width:31.3%;
		}
    @include media-sp { 
    width: 100%;
		max-width: 320px;
    margin: 0 auto 5px;
    }
}
#top #brand .sns li a {
  display: block;
  //float: left;
  letter-spacing: 0em;
  border: 1px solid #ccc;
  padding: 8px 0px;
  //margin: 0 10px;
  font-size: 90%;
  line-height: 140%;
  color: #ccc;
  text-decoration: none;
  display: block;
  
  
  position: relative;
		@include media-tab { 
		font-size: 80%;
		}
}
#top #brand .sns li a:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Safari用 */
  transform: translateY(-50%);
  left: 18px;
  color: #ccc;
  font: 16px "FontAwesome";
  @include media-tab { 
  left: 8px;
    font: 14px "FontAwesome";
  }
}
#top #brand .sns li.fb a:before {
  content: "\f230";
}
#top #brand .sns li.insta a:before {
  content: "\f16d";
}

#top #brand .sns li a:hover {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@include media-tab {
  #top #brand {
    padding: 30px 0;
  }
  #top #brand h2 {
    transition: 2s;
    font-size: 130%;
  }

  #top #brand .brandslider {
    -moz-box-shadow: 0 0 5px #ccc;
    -webkit-box-shadow: 0 0 5px #ccc;
    box-shadow: none;
    border: none;
    width: 90%;
    max-width: 90%;
    margin: 10px auto;
  }
  #top #brand .slick-prev {
    left: -15px;
  }
  #top #brand .slick-next {
    right: -15px;
  }
  #top #brand p {
    font-size: 75%;
  }
  #top #brand p small {
    padding-left: 0px;
    display: block;
  }
  /* Flashing */
  #top #brand img:hover {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s;
  }

  #top #brand .sns li a {
    
    
  }
  #top #brand .sns li a:before {
    
  }
}

/************************************

NEWS

************************************/
#news {
  background: $back-color1;
  padding: 80px 0;
}

#news .list {
  width: 90%;
  /*max-width:1150px;*/
  margin: 40px 5%;
  display: block; /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox; /*IE10*/
  display: -webkit-flex; /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
}
#news .list article {
  width: 21%;
  margin: 0 2% 0;
  text-align: left;
  background: #fff;
  /* 横方向　下方向　ぼかし　色＆透過 */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}

/*IE8▼*/
#news .list article {
  float: left\9;
}
/*IE9▼*/
#news .list article:not(:target) {
  float: left¥9;
}
/*IE10▼*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #news .list article {
    float: left !important;
  }
}
#news .list article a {
  text-decoration: none;
}

#news .list article a dl {
  padding: 20px;
}
#news .list article dl dt {
  font-size: 80%;
}
#news .list article a dl dd {
  font-size: 75%;
  text-align: right;
  color: #666666;
}
#news .list article a:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

#news .banner ul {
  width: 90%;
  /*max-width:1150px;*/
  margin: 40px 5%;
  letter-spacing: -1em;
}
#news .banner li {
  width: 46%;
  margin: 0 2% 20px;
  display: inline-block;
  letter-spacing: 0em;
}
/* Flashing */
#news .banner li a:hover {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@include media-tab {
  #news {
    padding: 50px 0;
  }

  #news .list {
    width: 84%;
    margin: 10px auto 30px auto;
  }
  #news .list article {
    width: 46%;
    margin: 0 2% 20px;
  }
  #news .list article:last-child {
    margin: 0 2% 20px;
  }
  #news .banner {
    margin: 0 8% 20px;
    width: 84%;
  }
  #news .banner li {
    margin: 0 0 10px;
    width: 100%;
  }
}
@include media-sp {
  #news {
    padding: 50px 0;
  }

  #news .list {
    width: 84%;
    margin: 10px auto 30px auto;
  }
  #news .list article {
    width: 100%;
    margin: 0 0px 20px;
  }
  #news .list article:last-child {
    margin: 0;
  }
}
/************************************

PICKUP

************************************/
#pickup {
  padding: 80px 0;
}
#top #pickup img {
  height: auto;
}

#top #pickup .pickslider {
  background: none;
  width: 90%;
  /*max-width:1160px;*/
  margin: 30px auto 80px auto;
}

#top #pickup li {
  position: relative;
  margin: 0 20px;
}
#top #pickup li .image {
  position: relative;
}
#top #pickup li .image .mask {
  width: 100%;
  height: 100%;
  position: absolute; /* 絶対位置指定 */
  top: 0;
  left: 0;
  opacity: 0; /* マスクを表示しない */
  background-color: rgba(0, 0, 0, 0.4); /* マスクは半透明 */
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#top #pickup li:hover .mask {
  opacity: 1; /* マスクを表示する */
}
/*
#top #pickup li:hover .mask:before{
  position: absolute;
  top: 50%;
  left: 0px;
  right:0;
  color: #fff;
  font: 40px 'FontAwesome';
  content: "\f107";
}
*/

#top #pickup li .mask p {
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  width: 100px;
  padding: 5px 15px;
}
#top #pickup li .mask p:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Safari用 */
  transform: translateY(-50%);
  left: 10px;
  color: #fff;
  font: 12px "FontAwesome";
  content: "\f105";
}
#top #pickup li .text {
  padding: 5% 0%;
  text-align: left;
}
#top #pickup li a {
  text-decoration: none;
}
#top .text .contact a {
  display: block;
  border: 1px solid #999;
  padding: 3px 0;
  margin: 0 0 8px;
  font-size: 80%;
  text-align: center;
  position: relative;
}
#top .text .contact a:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Safari用 */
  transform: translateY(-50%);
  left: 10px;
  color: #666;
  font: 14px "FontAwesome";
  content: "\f003";
}
#top .text .tel {
  display: none;
}
#top #pickup li .text h3 {
  font-size: 80%;
  font-family: $font-family-go;

  margin-bottom: 3px;
  font-weight: bold;
}
#top #pickup li .text h4 {
  font-size: 90%;
  font-weight: 500;
  margin-bottom: 3px;
}
#top #pickup li .text p {
  font-size: 80%;
  font-weight: 500;
  position: relative;
  padding-left: 16px;
}
#top #pickup li .text p a:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Safari用 */
  transform: translateY(-50%);
  left: 2px;
  color: #666;
  font: 12px "FontAwesome";
  content: "\f290";
}
#top #pickup li .text p .tel a {
  text-decoration: underline;
}

#top #pickup .slick-dots {
  position: absolute;
  bottom: -45px;
}
#top #pickup .slick-dots li {
  margin: 0 5px !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "FontAwesome";
  font-size: 30px;
  opacity: 0.75;
  color: #ccc;
}
.slick-prev:before {
  content: "\f104";
}
.slick-next:before {
  content: "\f105";
}
@include media-tab {
  #pickup {
    padding: 50px 0;
  }
  #top #pickup .pickslider {
    width: 86%;
    max-width: 100%;
    margin: 30px auto 60px auto;
  }
  #top #pickup li {
    position: relative;
    margin: 0 10px;
  }
  #top #pickup li .mask p {
    width: 60px;
  }

  #top #pickup li .text h3 {
    font-size: 75%;
  }
  #top #pickup li .text h4 {
    font-size: 80%;
  }
  #top #pickup li .text p {
    font-size: 70%;
    font-weight: 500;
    position: relative;
    padding-left: 16px;
  }
  #top .text .tel {
    display: block;
  }
  #top .text .tel a {
    display: block;
    border: 1px solid #999;
    padding: 3px 0;
    margin: 5px 0;
    font-size: 80%;
    text-align: center;
    position: relative;
  }
  #top .text .tel a:before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari用 */
    transform: translateY(-50%);
    left: 10px;
    color: #666;
    font: 14px "FontAwesome";
    content: "\f098";
  }
  #top .text .contact {
    display: none;
  }
  #top #pickup .slick-prev {
    left: -15px;
  }
  #top #pickup .slick-next {
    right: -15px;
  }
  #top #pickup .slick-dots {
    display: none;
  }
}
/************************************

watch_image

************************************/
#top #watch_image {
  width: 100%;
  /*height:600px;*/
  height: 70vh;
  background: url(../images/watch_image@2x.jpg) no-repeat;

  background-size: cover;
  position: relative;
}
@include media-tab {
  #top #watch_image {
    display: none;
  }
}
/************************************

parallax



div.parallax{
	height:600px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;	
	transition:7s;
	}
div.parallax.list-mv07{
	opacity: 1;
	transform: translate(0,-50px); 
	-webkit-transform: translate(0,-50px); 
}
div.parallax.list-mv07:not(:target) {
opacity: 1\9; 
}
div.parallax.mv07{
	opacity: 1.0;
	transform: translate(0,0); 
	-webkit-transform: translate(0,0);
}
.parallax_img_1{
	background:url(../images/watch_image@2x.jpg) no-repeat center ;
	background-size:cover;
	}
************************************/

/************************************

topmenu3 common

************************************/

#topmenu3 .title {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
#topmenu3 .title h2 {
  font-size: 28px;
  letter-spacing: 0.1em;
  display: inline-block;
  position: relative;
}
#topmenu3 .title h2:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  width: 90%;
  height: 2px;
  background: #fff;
}
#topmenu3 .title p {
  font-size: 80%;
  padding-top: 20px;
}
#topmenu3 .mask {
  width: 100%;
  height: 100%;
  position: absolute; /* 絶対位置指定 */
  top: 0;
  left: 0;
  opacity: 0; /* マスクを表示しない */
  background-color: rgba(0, 0, 0, 0.5); /* マスクは半透明 */
  -webkit-transition: all 0.2s ease;
  transition: all 0.5s ease;
}
#topmenu3 div:hover .mask {
  opacity: 1; /* マスクを表示する */
}

#topmenu3 .caption {
  position: absolute;
  left: 0%;
  right: 0;
}
#topmenu3 .caption h2 {
  margin: 0 0 10px;
  font-size: 130%;
}
#topmenu3 .caption p {
  font-size: 80%;
  line-height: 190%;
}
#topmenu3 .caption .more {
  border: 1px solid #fff;
  padding: 10px 40px 10px 30px;
  position: relative;
  display: inline-block;
  margin: 10px 0 0;
  font-size: 75%;
}
#topmenu3 .caption .more:before {
  position: absolute;
  top: 14px;
  right: 10px;
  color: #fff;
  font: 12px "FontAwesome";
  content: "\f105";
}

@include media-tab {
  #topmenu3 .title h2 {
    font-size: 21px;
  }
  #topmenu3 .caption h2 {
    margin: 0 0 5px;
    font-size: 100%;
  }
  #topmenu3 .caption p {
    display: none;
  }
  #topmenu3 .caption .more {
    padding: 5px 40px 5px 30px;
    position: relative;
    display: inline-block;
    margin: 0px 0 0;
    font-size: 79%;
  }
}
/************************************

WATCH

************************************/
#watch {
  float: left;
  background: url(../images/watch@2x.jpg) no-repeat center 0;
  background-size: cover;
  width: 33.4%;
  height: 400px;
  position: relative;
  color: #fff;
  transition: 1s;
}

#topmenu3 #watch .title {
  top: 40%;
}
#topmenu3 #watch .caption {
  top: 30%;
  left: 0%;
  right: 0;
}
@include media-tab {
  #watch {
    background: url(../images/watch-sp.jpg) no-repeat center 0;
    background-size: cover;
    width: 100%;
    height: 0;
    /* 表示画像の高さ ÷ 表示画像の幅 × 100 */
    padding-top: 35.156%;
    position: relative;
    color: #fff;
    transition: 1s;
  }
  #topmenu3 #watch .caption {
    top: 20%;
    left: 0%;
    right: 0;
  }
}
/************************************

JEWELRY

************************************/
#jewelry {
  background: url(../images/jewelry.jpg) no-repeat center 0;
  background-size: cover;
  width: 33.3%;
  height: 400px;
  position: relative;
  float: left;
  color: #333;
  transition: 1s;
}
#topmenu3 #jewelry .title {
  top: 35%;
}
#topmenu3 #jewelry .title h2:after {
  background: #333;
}
#topmenu3 #jewelry .caption {
  top: 30%;
  left: 0%;
  right: 0;
  color: #fff;
}

@include media-tab {
  #jewelry {
    background: url(../images/jewelry-sp.jpg) no-repeat center 0;
    background-size: cover;
    width: 50%;
    height: 0;
    /* 表示画像の高さ ÷ 表示画像の幅 × 100 /2 */
    padding-top: 35.156%;
    position: relative;
    float: left;
    color: #333;
    transition: 1s;
  }
  #topmenu3 #jewelry .caption {
    top: 20%;
    left: 0%;
    right: 0;
    color: #fff;
  }
  #topmenu3 #jewelry .caption h2 {
    font-size: 100%;
    margin: 0 0 5px;
  }
}
/************************************

BRIDAL

************************************/
#bridal {
  background: url(../images/bridal@2x.jpg) no-repeat center 0;
  background-size: cover;
  width: 33.3%;
  height: 400px;
  position: relative;
  float: right;
  color: #fff;
  transition: 2s;
}

#topmenu3 #bridal .title {
  top: 35%;
}
#topmenu3 #bridal .caption {
  top: 30%;
  left: 0%;
  right: 0;
}

@include media-tab {
  #bridal {
    background: url(../images/bridal-sp.jpg) no-repeat center 0;
    background-size: cover;
    width: 50%;
    height: 0;
    /* 表示画像の高さ ÷ 表示画像の幅 × 100 /2 */
    padding-top: 35.156%;
    position: relative;
    float: right;
    color: #fff;
    transition: 2s;
  }
  #topmenu3 #bridal .caption {
    top: 20%;
    left: 0%;
    right: 0;
  }
  #topmenu3 #bridal .caption h2 {
    font-size: 100%;
    margin: 0 0 5px;
  }
}
/************************************

SHOP

************************************/
#shop {
  padding: 80px 0;
}
#shop .list {
  width: 90%;
  margin: 40px auto 10px;
  display: block; /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox; /*IE10*/
  display: -webkit-flex; /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
}
#shop .list article {
  width: 21%;
  margin: 0 2%;
  text-align: left;
  background: #fff;
}

/*IE8▼*/
#shop .list article {
  float: left\9;
}
/*IE9▼*/
#shop .list article:not(:target) {
  float: left¥9;
}
/*IE10▼*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #shop .list article {
    float: left !important;
  }
}
#shop .list article a {
  text-decoration: none;
}
#shop .list article p {
  padding: 0px 0;
  font-size: 80%;
}

#shop a:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}
#shop .list article .images {
  margin: 0 0 10px;
}
/** banner**/
#shop .banner {
  margin: 50px 0 70px;
}
#shop .banner ul li {
  display: inline-block;
  margin: 0 10px;

  height: auto;
}
#shop .banner ul li img {
  border: 1px solid #ccc;
  box-sizing: border-box;
}
/** sns **/
#shop .sns {
  width: 90%;
  margin: 0 auto;
}
#shop .sns article {
  width: 46%;
  float: left;
  margin: 0 2%;
}

#shop .sns article h3 {
  margin: 0 0 30px;
}
#shop .sns article h3 a {
  text-decoration: none;
}
#shop #fb_page_plugin_area {
  width: 100%;
}
#shop .sns iframe {
  width: 100%;
}
#shop .sns article .bt {
  margin: 20px 0 0;
}

@include media-tab {
  #shop {
    padding: 50px 0;
  }
  #shop .list {
    width: 86%;
    margin: 10px auto;
  }
  #shop .list article {
    width: 46%;
    margin: 0 2% 20px;
  }

  #shop .list article:last-child {
    margin: 0 2%;
  }
  #shop .list article a p {
    padding: 7px 0;
    font-size: 75%;
  }
  #shop .banner {
    margin: 30px 8% 30px;
  }
  #shop .banner ul li {
    display: block;
    float: left;
    width: 46%;
    margin: 0 2% 10px;
  }
  /** sns **/
  #shop .sns {
    width: 90%;
    margin: 0 auto;
  }
  #shop .sns article {
    width: 100%;
    float: none;
    margin: 0 0 30px;
  }

  #shop .sns article h3 {
    margin: 0 0 30px;
  }
  #shop .sns article h3 a {
    text-decoration: none;
  }
  #shop #fb_page_plugin_area {
    width: 100%;
  }
  #shop .sns article iframe {
    width: 100%;
  }
}
/************************************

.instagram

************************************/
.instagram {
  margin: 0 0 60px;
		.wrapper8{
		max-width: 1200px;
		}
  article {
    //float: left;
    width: 100%;
    margin: 40px 0%;
    @include media-sp {
      margin: 20px 0 ;
    }
  }
		a{
		text-decoration: none;
		}
		.bt a::before{
		display: none;
		}
}
.insta__wrap{
display: block; 
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
-webkit-flex-wrap: wrap;
flex-wrap:  wrap;
//width:96%;
margin: 0px ;
box-sizing: border-box;
padding: 10px 0;
@include media-tab { 
//width:96%;
}
	li{
	box-sizing: border-box;
	width:calc(50% - 10px);
	margin:5px;
	position: relative;
	&::before{
  content: "";
  display: block;
  padding-top: 100%;
}
	
			img{
			position: absolute;
			width:100%;
			height:100%;
			top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
			}
	}
}

/************************************

#brand_logo

************************************/
#brand_logo {
  margin: 70px 0;
  ul {
    display: block; /* before Android4.3 iOS6:Safari */
    display: -ms-flexbox; /*IE10*/
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-flex-wrap: wrap; /* Safari */
    flex-wrap: wrap;
    li {
      width: 14.6%;
      margin: 0 1% 30px;
      font-size: 70%;
      @include media-tab {
        width: 23%;
      }
      @include media-sp {
        width: 31.3%;
      }
      img {
        width: 80%;
        @include media-sp {
          width: 90%;
        }
      }
    }
  }
}
/************************************

#banner

************************************/

#banner {
  padding: 50px 0;
  @include media-tab {
    padding: 25px 0;
  }
}
#banner ul {
  letter-spacing: -1em;
  max-width: 94%;
  margin: 0 auto;
}
#banner ul li {
  letter-spacing: 0;
  display: inline-block;
  margin: 0 10px;
  height: auto;
  @include media-tab {
    margin: 1%;
    width: 23%;
  }
  @include media-sp {
    margin: 1%;
    width: 48%;
  }
  &.japantax {
    display: none;
    @include media-tab {
      display: inline-block;
    }
  }
}
#banner ul li img {
  border: 1px solid #ccc;
  box-sizing: border-box;
}
#japantaxfree {
margin:20px 0 0;
img{
width:70px;
}
}